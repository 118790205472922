.dd-handle.with_inputs {
    height: 42px;
}

.dd3-handle::before {
    top: 9px;
}

.dd3-content.with_inputs {
    height: 42px;
}

.dd3-content.with_inputs > div {
    line-height: 32px;
}

.page-logo {
    min-width: 150px;
}

.page-logo > a {
    width: 80%;
    height: 90%;
    display: block !important;
}

.page-logo div.logo {
    margin-top: 5px !important;
    width: 100%;
    height: 100%;
    background: center no-repeat;
    background-size: contain;
}

.page-sidebar-closed .page-logo > a {
    display: none !important;
}

.twitter-typeahead {
    display: inline-block !important;
}

input.tt-hint {
    height: 34px !important;
    padding: 6px 12px !important;
}

.tt-menu {
    border-color: #c2cad8 !important;
}

div.token {
    height: 30px !important;
    line-height: 30px !important;
    background-color: #f2f6f9 !important;
    border-color: #5b9bd1 !important;
    color: #5b9bd1 !important;
}

.tt-suggestion.tt-cursor {
    background-image: none !important;
    background-repeat: no-repeat !important;
    background-color: #f2f6f9 !important;
    color: #5b9bd1 !important;
}

.close {
    background-position: center;
    line-height: 30px;
    padding-right: 0;
    margin-right: 4px;
}

@media (max-width: 991px) {

    .page-header.navbar .page-logo {
        width: 80% !important;
    }
}

@media (max-width: 767px) {

    .page-header.navbar .page-logo {
        width: 80% !important;
    }
}
/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */

/*# sourceMappingURL=custom.css.map */
